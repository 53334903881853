
.forExhibitors,
.forVisitors {

   .row {
        display: flex;
        align-items: center;
        &:first-child {
            margin-top: 100px;
        }
        &:last-child {
            margin-bottom: 100px;
        }
        .info-banner {
            text-align: center;
            max-width: 480px;
            margin: 0 auto;
            padding: 60px;
            min-height: 450px;
            img {
                max-height: 85px;
                max-width: 75px;
                margin-bottom: 30px;
            }
            h4 {
                color: #000;
                font-size: 24px;
                font-weight: 400;
                margin-bottom: 20px;
            }
            p {
                font-size: 14px;
                color: #000;
                font-weight: 400;
                margin-bottom: 20px;
                text-align: justify;                
                height: 100px;
            }
            a {
                font-size: 14px;
                padding: 10px 50px;
                color: #507eba;
                border: 2px solid #507eba;
                border-radius: 20px;
                text-transform: uppercase;
                font-weight: 500;
                transition: .4s all;
                &:hover {
                    background: #507eba;
                    color: #fff;
                    text-decoration: none; 
                }
            }
            &._3 {
                // img {
                //     margin-top: 5px; 
                // }
                p {
                    min-height: 100px;
                }
            }
            &._1,
            &._3 {
                float: right;
            }
            &._2,
            &._4 {
                float: left;
                position: relative;
                top: -1px;
                left: -1px;
            }
            &._1 {
                border-bottom: 1px solid #ededed;
                border-right: 1px solid #ededed;
            }
            &._4 {
                border-top: 1px solid #ededed;
                border-left: 1px solid #ededed;
            }
            img {
                max-height: 85px;
                max-width: 75px;
                margin-bottom: 30px;
            }
            h4 {
                color: #000;
                font-size: 24px;
                font-weight: 700;
                margin-bottom: 20px;
            }
            p {
                font-size: 14px;
                color: #000;
                font-weight: 400;
                margin-bottom: 20px;
            }
            a {
                font-size: 14px;
                padding: 10px 10px;
                color: #507eba;
                border: 2px solid #507eba;
                border-radius: 20px;
                display: block;
                max-width: 140px;
                margin: 0 auto;
            }
        }
    }
}
.forVisitors {
    .row {
        .info-banner {
            &._4 {
                img {
                    margin-bottom: 20px !important; 
                }
            }
            a {
                color: #bed54b;
                border: 2px solid #bed54b;
                &:hover {
                    background: #bed54b;
                    color: #fff;
                    text-decoration: none; 
                }
            }
        }
    }
}
.forExhibitors-regulations {   
    .row {     
        .info-banner {
            text-align: center;
            max-width: 480px;
            margin: 0 auto;
            padding: 60px;
            h4 {
                color: #000;
                font-size: 24px;
                font-weight: 400;
                margin-bottom: 20px;
            }    
            p {
                font-size: 14px;
                color: #000;
                font-weight: 400;
                margin-bottom: 20px;
                text-align: justify;
                height: 125px;
            }
            a {
                font-size: 14px;
                padding: 10px 10px;
                color: #507eba;
                border: 2px solid #507eba;
                border-radius: 20px;
                text-transform: uppercase;
                font-weight: 500;
                display: block;
                max-width: 140px;
                margin: 0 auto;
                &:hover {
                    background: #507eba;
                    color: #fff;
                    text-decoration: none;
                }
            }  
        }   
    }
    .regulations {
        list-style-type:decimal;
        list-style-type: upper-roman;
        max-width: 900px;
        margin: 0 auto;        
        li {
            color: #000;
            font-size: 16px;
            margin-bottom: 35px;
            .inner-ul {
                li {
                    list-style-type: lower-roman;
                    padding: 0;
                    margin-bottom: 5px;
                }
            }
        }
        // & > li:before {
        //     font-weight: 700;
        // }
    }
    .regulations._2 {
        list-style-type: decimal;
    }
    .dllink {        
        max-width: 810px;
        margin: 0 auto;
    }
    .see-more {
        margin-top: 100px;
        border-bottom: 2px solid #245ea8;
        font-size: 14px;
        span {
            color: #245ea8;
            text-align: center;
            text-transform: uppercase;
            font-weight: 500;
            display: block;
            margin-bottom: 10px;
            padding: 0;
        }
    }
}

.forExhibitors-application {
    .row {      
        .info-banner {
            text-align: center;
            margin: 0 auto;
            padding: 60px;
            img {
                max-height: 85px;
                max-width: 75px;
                margin-bottom: 30px;
            }        
            h4 {
                color: #000;
                font-size: 24px;
                font-weight: 400;
                margin-bottom: 20px;
                line-height: 1.4;
            }
            p {
                font-size: 14px;
                color: #000;
                font-weight: 400;
                margin-bottom: 20px;
                text-align: justify;
                height: 100px;
            }
            a {
                font-size: 14px;
                padding: 10px 10px;
                color: #507eba;
                border: 2px solid #507eba;
                border-radius: 20px;
                text-transform: uppercase;
                display: block;
                max-width: 140px;
                margin: 0 auto;
                &:hover {
                    background: #507eba;
                    color: #fff;
                    text-decoration: none;
                }
            }
        }    
    }

    .regulations {
        list-style-type:decimal;
        max-width: 900px;
        margin: 0 auto;
        li {
            color: #000;
            font-size: 14px;
            margin-bottom: 35px;
        }
    }
    .see-more {
        border-bottom: 2px solid #245ea8;
        font-size: 14px;        
        margin-top: 100px;
        span {
            color: #245ea8;
            text-align: center;
            text-transform: uppercase;
            font-weight: 500;
            display: block;
            margin-bottom: 10px;
            padding: 0;
        }
    }
    .target,
    .advantage,
    .localization {		
        height: 480px;
        margin-bottom: 100px;
        .image {
            height: 100%;
            width: 48%;
            background-size: cover;
            background-repeat: no-repeat;
            &.right {
                float: right !important;
            }
            &.left {
                float: left !important;
            }
        }
        .descr {
            width: 490px;
            height: 270px;
            background-color: #f0f0f0;
            position: relative;	
            float: right;
            margin-right: -30px;
            top: 120px;
            top: calc(50% - 120px);
            padding-top: 30px;
            padding-left: 35px;
            h4 {
                font-size: 24px;
                font-weight: 500;
                text-transform: none;
                margin-bottom: 40px;

            }
            p {
                font-size: 24px;
                color: #000;
            }
        }
    }
    .advantage {
        .image {
            background-image: url('../../src/assets/img/40.jpg');
        }
        .descr {
            float: left;
            // margin-left: -140px;
        }
    }
    .text-box {
        max-width: 800px;
        width: 100%;
        margin: 0 auto;
        color: #000;
        margin-top: 100px;
        margin-bottom: 80px;
        p {
            font-size: 24px;

        }
        ul {
            font-size: 24px;
            li {
                margin-bottom: 10px;
                span {
                    color: #245ea8;
                }
                a {
                    color: #245ea8;
                }
                &.dllink {
                    margin-bottom: 10px;
                    list-style: none;
                    // padding-left: 5px;
                }
            }
        }
    }
    //reklama
    h3 {
        text-transform: uppercase;
        text-align: center;
        font-weight: 400;
    }
    table {
        background-color: transparent;
        width: 70%;
        margin: 0 auto;
        font-size: 16px;
        tr {
            width: 100%;
            th {
                width: 50%;
            }
            td {
                border: 1px solid black;
                width: 50%;
                padding: 8px;
            }
        }
    }
    span {
        text-align: center;
        transition: .3s all;
        text-decoration: none;
        text-transform: uppercase;
        margin-top: 15px;
        &:hover {
            font-weight: 700;
        }
    }
}
.forExhibitors-drive {
    .row {      
        .info-banner {
            text-align: center;
            max-width: 900px;
            margin: 0 auto;
            padding: 60px;
            .icon {
                margin-bottom: 30px;
            }          
            h4 {
                color: #000;
                font-size: 24px;
                font-weight: 400;
                margin-bottom: 20px;
            }
            p {
                font-size: 14px;
                color: #000;
                font-weight: 400;
                margin-bottom: 20px;
                text-align: justify;
                height: 100px;
            }
            a {
                font-size: 14px;
                padding: 10px 10px;
                color: #507eba;
                border: 2px solid #507eba;
                border-radius: 20px;
                text-transform: uppercase;
                font-weight: 500;      
                display: block;
                max-width: 140px;
                margin: 0 auto;
                transition: .4s all;
                &:hover {
                    background: #507eba;
                    color: #fff;
                    text-decoration: none;
                }
            }
           
        }

        .image-box {
            max-width: 900px;
            margin: 0 auto;
        }

        .image-accommodation {
            margin: 0 auto;
            img {
                width: 100%;
            }
        }

        .box-accommodation {
            background-color: #f0f0f0;
            padding: 40px;
            margin-bottom: 30px;
            h2 {
                margin: 0;
                font-size: 24px;
                color: #000;
                position: relative;
                display: inline-block;
            }
            .price {
                margin: 0;
                color: #154c98;
                font-size: 24px;
                position: relative;
                display: inline-block;
                float: right;
            }

            .border-line {
                border-top: 2px solid #000;
                max-width: 100px;
                width: 100%;
                height: 2px;
                display: block;
                margin-top: 50px;
                margin-bottom: 30px;
            }

            ul {
                display: block;
                padding: 0;
                margin: 20px 0;
                li {
                    background: url('../../src/assets/img/star.png') center center no-repeat;
                    background-size: cover;
                    width: 22px;
                    height: 21px;
                    list-style: none;
                    display: inline-block;
                    &.active {
                        background: url('../../src/assets/img/star_active.png') center center no-repeat;
                    }
                }
            }

            p {
                color: #000;
                font-size: 14px;
                display: block;
            }

            a {
                color: #000;
                font-size: 14px;
                display: block;
                text-transform: uppercase;
            }
        }

        .image {
            background: url('../../src/assets/img/52.png') center center no-repeat;
            background-size: cover;
            height: 100%;
            &:after {
                content: "";
                clear: both;
                display: block;
            }
        }
    }
    .regulations {
        list-style-type:decimal;
        max-width: 900px;
        margin: 0 auto;
        li {
            color: #000;
            font-size: 14px;
            margin-bottom: 35px;
        }

    }
    .see-more {
        border-bottom: 2px solid #245ea8;
        font-size: 14px;
        margin-top: 100px;
        span {
            color: #245ea8;
            text-align: center;
            text-transform: uppercase;
            font-weight: 500;
            display: block;
            margin-bottom: 10px;
            padding: 0;
        }
    }
    .left-headling {
        border-bottom: 2px solid #245ea8;
        font-size: 14px;
        span {
            color: #245ea8;
            text-align: center;
            text-transform: uppercase;
            font-weight: 500;
            display: block;
            margin-bottom: 10px;
            padding: 0;
            margin-left: 120px;
        }
    }
    .right-headling {
        font-size: 140px;
        position: relative;
        top: -80px;
        margin-left: 200px;

        span {
            color: #ededed;
            text-align: center;
            font-weight: 500;
            display: block;
            margin-bottom: 10px;
            padding: 0;

        }
    }
    .target,
    .advantage,
    .localization {		
        height: 480px;
        margin-bottom: 100px;
        .image {
            height: 100%;
            width: 48%;
            background-size: cover;
            background-repeat: no-repeat;
            &.right {
                float: right !important;
            }
            &.left {
                float: left !important;
            }
        }
        .descr {
            width: 490px;
            height: 270px;
            background-color: #f0f0f0;
            position: relative;	
            float: right;
            margin-right: -30px;
            top: 120px;
            top: calc(50% - 120px);
            padding-top: 30px;
            padding-left: 35px;
            h4 {
                font-size: 24px;
                font-weight: 500;
                text-transform: none;
                margin-bottom: 40px;

            }
            p {
                font-size: 24px;
                color: #000;
            }
        }
    }
    .advantage {
        .image {
            background-image: url('../../src/assets/img/7.jpg');
        }
        .descr {
            float: left;
            // margin-left: -140px;
        }
    }
    .text-box {
        max-width: 800px;
        width: 100%;
        margin: 0 auto;
        color: #000;
        margin-top: 100px;
        margin-bottom: 80px;
        text-align: center;
        p {
            font-size: 24px;

        }

    }
    .drive-title > h3 {
        margin: 100px 0 60px;
        color: #ededed;
        font-size: 140px;
        text-align: right;
        font-weight: 300;
        position: relative;
    }
    .drive-title {
        .aside-small-header {
            width: 640px;
            position: absolute;
            left: 0;
            margin-top: -123px;
            h4 {
                background: unset;
                background-clip: border-box;
                -webkit-background-clip: unset;
                -webkit-text-fill-color: unset;
                color: #154c98;
            }            
            hr {
                   background: #154c98;
              
            }
        }
    }
}
.contact {
    & > h3 {
        margin: 100px 0 60px;
        color: #ededed;
        font-size: 140px;
        text-align: center;
        font-weight: 300;
        position: relative;
    }
    .row {
        //   height: 590px;
        //    display: flex;
        //   align-items: center;
        .image-box {
            max-width: 900px;
            margin: 0 auto;
            margin-top: 100px;
            margin-bottom: 50px;
            img {
                width: 100%;
            }
        }
        .image-contact {
            margin: 0 auto;
            img {
                width: 100%;
            }
        }
        .box-contact {
            background-color: #f0f0f0;
            padding: 66px; 
            padding-top: 15px;
            padding-bottom: 15px;           
            min-height: 366px;
            p {
                color: #000;
                font-size: 18px;
                display: block;
            }
            a {
                color: #245ea8;
                display: block;                
                font-weight: 700;
            }
            span {
                color: #245ea8;
                font-weight: 700;
            }
        }
        .image {
            background: url('../../src/assets/img/52.png') center center no-repeat;
            background-size: cover;
            height: 100%;
            &:after {
                content: "";
                clear: both;
                display: block;
            }
        }
    }
    .files {
        div:first-child div{
            img {
                margin-top: 13px;
            }
        }
    }
    .regulations {
        list-style-type: decimal;
        max-width: 900px;
        margin: 0 auto;
        li {
            color: #000;
            font-size: 14px;
            margin-bottom: 35px;
        }
    }
    .see-more {
        border-bottom: 2px solid #245ea8;
        font-size: 14px;
        span {
            color: #245ea8;
            text-align: center;
            text-transform: uppercase;
            font-weight: 500;
            display: block;
            margin-bottom: 10px;
            padding: 0;
        }
    }
    .left-headling {
        border-bottom: 2px solid #245ea8;
        font-size: 14px;
        span {
            color: #245ea8;
            text-align: center;
            text-transform: uppercase;
            font-weight: 500;
            display: block;
            margin-bottom: 10px;
            padding: 0;
            margin-left: 120px;
        }
    }
    .right-headling {
        font-size: 140px;
        position: relative;
        top: -80px;
        margin-left: 200px;

        span {
            color: #ededed;
            text-align: center;
            font-weight: 500;
            display: block;
            margin-bottom: 10px;
            padding: 0;

        }
    }
    .target,
    .advantage,
    .localization {		
        height: 480px;
        margin-bottom: 100px;
        .image {
            height: 100%;
            width: 48%;
            background-size: cover;
            background-repeat: no-repeat;
            &.right {
                float: right !important;
            }
            &.left {
                float: left !important;
            }
        }
        .descr {
            width: 490px;
            height: 270px;
            background-color: #f0f0f0;
            position: relative;	
            float: right;
            margin-right: -30px;
            top: 120px;
            top: calc(50% - 120px);
            padding-top: 30px;
            padding-left: 35px;
            h4 {
                font-size: 24px;
                font-weight: 500;
                text-transform: none;
                margin-bottom: 40px;

            }
            p {
                font-size: 24px;
                color: #000;
            }
        }
    }
    .advantage {
        .image {
            background-image: url('../../src/assets/img/7.jpg');
        }
        .descr {
            float: left;
            margin-left: -140px;
        }
    }
    .text-box {
        max-width: 800px;
        width: 100%;
        margin: 0 auto;
        color: #000;
        margin-top: 100px;
        margin-bottom: 80px;
        text-align: center;
        p {
            font-size: 24px;

        }
    }
    form {
        margin-bottom: 100px;
        input {
            font-size: 14px;
            padding: 20px 30px;
            margin: 10px 0;
            width: 100%;
            background-color: transparent;
            border: 1px solid #dfdfdf;
        }
        textarea {
            font-size: 14px;
            padding: 20px 30px;
            width: 100%;
            background-color: transparent;
            border: 1px solid #dfdfdf;
        }
        .button-box {
            display: block;
            margin: 0 auto;
            width: auto;
            .send-button {
                font-size: 14px;
                background: linear-gradient(-90deg, #6fb52b, #afca1e);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                text-transform: uppercase;
                border: 0;
                width: auto;
                display: block;
                margin: 0 auto;
            }
            hr {
                height: 2px;
                width: 50px;
                border: none;
                background: linear-gradient(-90deg, #6fb52b, #afca1e);
                margin-bottom: 10px;
                margin-top: -10px;
                transition: .5s all;
            }
            .send-button:hover + hr {
                width: 100px;
                left: -25px;
            }
        }
        ::placeholder {
            color: #000;
            opacity: 1;
        }
    }
    .form-title > h3 {
        margin: 100px 0 60px;
        color: #ededed;
        font-size: 140px;
        text-align: center;
        font-weight: 300;
        position: relative;
        white-space: nowrap;
    }

    .form-title {
        .aside-small-header {
            width: 640px;
            position: absolute;
            left: 0;
            margin-top: -123px;
            h4 {
                background: unset;
                background-clip: border-box;
                -webkit-background-clip: unset;
                -webkit-text-fill-color: unset;
                color: #000;
            }
            hr {
                background: #000;
            }

        }
    }    
    .map {
        display: block;
        margin-top: 100px;
        height: 600px;
    }
}